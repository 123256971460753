<template>
  <div class="evaluation mt-5 cart-Page cart-Page-adrreess">
    <div class="topboxorder">
      <div class="current-orders py-4">
        <div class="icons d-flex justify-content-center">
          <b-icon icon="circle-fill" class="orange circle-icon h4"></b-icon>
          <b-icon icon="dash" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash" class="orange circle-icon"></b-icon>
          <b-icon icon="circle-fill" class="orange circle-icon h4"></b-icon>
          <b-icon icon="dash" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="orange circle-icon"></b-icon>
          <b-icon icon="dash" class="orange circle-icon"></b-icon>
          <b-icon icon="circle-fill" class="pink circle-icon h4"></b-icon>
          <b-icon icon="dash" class="pink circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
          <b-icon icon="dash-lg" class="pink circle-icon"></b-icon>
          <b-icon icon="dash" class="pink circle-icon"></b-icon>
          <b-icon icon="circle-fill" class="pink circle-icon h4"></b-icon>
        </div>
        <div class="order-done d-flex justify-content-center pt-3">
          <span class="mx-5"> {{ $t("shopping cart") }} </span>
          <span class="mx-5"> {{ $t("shipping address") }} </span>
          <span class="mx-5"> {{ $t("payment") }} </span>
          <span class="mx-5">{{ $t("Submit a request") }} </span>
        </div>
      </div>
    </div>

    <div class="container items">
      <h5 class="d-inline-block ms-2 mb-3">{{ $t("shipping address") }}</h5>
      <div class="shopping-cart d-flex w-100 gap-3">
        <div class="paying p-2">
          <div class="from-all">
            <div class="row">
              <div class="col-md-6">
                <div
                  class="address-add-btn d-flex align-items-center gap-2 p-2 mb-2 m-0 w-100"
                  @click="$router.push('/shippingAddress/addMap')"
                >
                  <div class="">
                    <img
                      src="../../assets/images/map.svg"
                      class="img-fluid map1 rounded-start m-0"
                      alt=""
                    />
                  </div>
                  <div class="map2">
                    {{ $t("Determine the location on the map") }}
                    {{ body.lat }}, {{ body.lng }}
                  </div>
                  <div class=" ">
                    <img
                      src="../../assets/images/mapLeft.svg"
                      class="img-fluid rounded-start"
                      alt=""
                      style="cursor: pointer"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="add-info">
              <h5>{{ $t("Write the address") }}</h5>
              <p class="from-p text-start">
                {{
                  $t(
                    "Fill in the website address data to view delivery charges"
                  )
                }}
              </p>
            </div>

            <form method="post" @submit.prevent="save()">
              <div class="row g-3">
                <div class="col-md-5">
                  <label for="firstName" class="form-label"
                    >{{ $t("firstName") }}
                  </label>
                  <input
                    v-model="user.userName"
                    
                    class="from-taab form-control"
                    id="firstName"
                    :placeholder="$t('firstName')"
                  />
                </div>
                <!-- <div class="col-md-5">
                  <label for="lastName" class="form-label">
                    {{ $t("lastName") }}</label
                  >
                  <input
                    v-model="body.lastName"
                    
                    class="from-taab form-control"
                    id="lastName"
                    :placeholder="$t('lastName')"
                  />
                </div> -->

                <div class="col-md-5">
                  <label for="phone" class="form-label">{{
                    $t("phone")
                  }}</label>
                  <vue-tel-input
                    :placeholder="$t('Please enter the mobile number')"
                    v-model="user.phone"
                    :class="{ 'is-invalid': isInvalid && !body.phone }"
                    class="from-taab form-control"
                    v-bind="{
                      mode: 'international'
                    }"
                    style="direction: initial"
                  >
                  </vue-tel-input>
                </div>
                <div class="col-md-5">
                  <label for="email" class="form-label">
                    {{ $t("E-mail") }}
                  </label>
                  <input
                    type="email"
                    v-model="user.email"
                    :class="{ 'is-invalid': isInvalid && !body.email }"
                    class="from-taab form-control"
                    id="email"
                    :placeholder="$t('E-mail')"
                  />
                </div>

                <!-- <div class="col-md-6">
                  <label for="countryId" class="form-label"> الدوله </label>

                  <Dropdown
                    v-model="body.countryId"
                    :options="countresList"
                    optionLabel="name.ar"
                    optionValue="_id"
                    placeholder="اختار الدوله"
                    style="width: 100%"
                    class="from-taab"
                    :filter="true"
                    :showClear="true"
                  />
                </div> -->
                <div class="col-md-6">
                  

                  <label for="govId" class="form-label"> {{ $t("regions") }} </label>
                  <Dropdown
                    v-model="body.govId"
                    :options="govesList"
                    optionLabel="name.ar"
                    optionValue="_id"
                    :placeholder="$t('chooseRegions')"
                    style="width: 100%"
                    class="from-taab"
                    :filter="true"
                    :showClear="true"
                    @change="getCities($event)"
                    
                  />
                </div>

                <div class="col-md-5">
                  <label for="city" class="form-label">{{ $t("City") }}</label>
                  <!-- <Dropdown v-model="body.cityId" :options="citesList" optionLabel="name.ar" placeholder="$t('Select the city')" /> -->

                  <Dropdown
                    v-model="body.cityId"
                    :options="citesList"
                    optionLabel="name.ar"
                    optionValue="_id"
                    :placeholder="$t('Select the city')"
                    style="width: 100%"
                    class="from-taab"
                    :filter="true"
                    :showClear="true"
                    
                    
                  />
                </div>
                <!-- <div class="col-md-5">
                  <label for="inputEmail4" class="form-label">{{
                    $t("District")
                  }}</label>

                  <Dropdown
                    v-model="body.regionId"
                    :options="regionesList"
                    optionLabel="name.ar"
                    optionValue="_id"
                    :placeholder="$t('Select the District')"
                    style="width: 100%"
                    class="from-taab"
                    :filter="true"
                    :showClear="true"
                  />
                </div> -->
                <!-- <div class="col-md-4"></div> -->

                <div class="col-md-5">
                  <label for="inputEmail4" class="form-label">
                    {{ $t("Street/building") }}</label
                  >
                  <input
                    v-model="body.address"
                    :class="{ 'is-invalid': isInvalid && !body.address }"
                    class="from-taab form-control"
                    id="inputEmail4"
                    :placeholder="$t('Street/building')"
                  />
                </div>
                <div class="col-md-5"></div>

                <div class="col-md-5">
                  <button
                    class="btn-addaddress btn mt-4"
                    type="submit"
                    :disabled="disabledAdd"
                  >
                    {{ $t("save") }}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="discount-box">
          <div class="discount">
            <div class="gifts">
              <p>{{ $t("Discount coupons and gift cards") }}</p>
              <div class="btns d-flex justify-content-between">
                <input
                  type="text"
                  class="sign1"
                  :value="cart.discountId.name"
                  :placeholder="$t('Enter the coupon or card code')"
                  v-if="cart.discountId"
                  disabled
                />
                <input
                  v-else
                  type="text"
                  class="sign1"
                  v-model="discountCode"
                  :placeholder="$t('Enter the coupon or card code')"
                  @keyup.enter="checkDescount()"
                />
                <button
                  class="sign2"
                  @click="deleteDiscount()"
                  v-if="cart.discountId"
                >
                  {{ $t("cncel") }}
                </button>
                <button class="sign2" @click="checkDescount()" v-else>
                  {{ $t("use") }}
                </button>
              </div>
            </div>

            <div class="orders-summary">
              <h5>
                {{ $t("Order summary") }}
              </h5>
              <div class="date d-flex justify-content-between mb-2">
                <p>
                  {{ $t("Subtotal") }}
                </p>
                <span class="price">{{ cart.subTotal }} {{ $t("SAR") }}</span>
              </div>
              <div
                class="date d-flex justify-content-between mb-2"
                v-if="cart.discountValue > 0"
              >
                <p>{{ $t("Discount") }}</p>
                <span class="price"
                  >-{{ cart.discountValue }} {{ $t("SAR") }}</span
                >
              </div>
              <div class="date d-flex justify-content-between mb-2">
                <p>{{ $t("Total") }}</p>
                <span class="price">{{ cart.total }} {{ $t("SAR") }}</span>
              </div>
            </div>

            <div class="delivery-costs mt-4">
              <h6>
                {{ $t("Delivery costs") }}
              </h6>
              <p>
                {{
                  $t("It will be calculated after adding the delivery address")
                }}
              </p>
              <!-- <div class="plus-btn2"> -->
              <div class="text-center">
                <router-link
                  to="/shippingAddress"
                  class="border-0 btn-done-cart px-0 mt-2 d-block"
                >
                  {{ $t("Complete your purchase") }}
                </router-link>
              </div>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cart: {
        subTotal: 0,
        total: 0,
        cartItemsList: []
      },
      user:{
        email:null,
        phone:null,
        userName:null
      },
      body: {
        govId: null,
        countryId: null,
        cityId: null,
        regionId: null,
        firstName: null,
        lastName: null,
        address: null,
        phone: null,
        email: null,
        lat: null,
        lng: null
      },
      isInvalid: false,
      disabledAdd: false,
      countresList: [],
      govesList: [],
      citesList: [],
      regionesList: [],
      discountCode: null,
      list: []
    };
  },
  methods: {
    getCities(e) {
      
      if (e.value && e.value != null && e.value != "") {
        this.$http
          .post("city/getCityByGov?limit=5000", { govId: e.value })
          .then((res) => {
            this.citesList = res.data.docs;
            
          });
      } else {
        this.regionesList = [];
      }
    },
    getRegions(e) {
      if (e.value && e.value != null && e.value != "") {
        this.$http
          .post("region/getRegionByCity?limit=5000", { cityId: e.value })
          .then((res) => {
            this.regionesList = res.data.docs;
          });
      } else {
        this.regionesList = [];
      }
    },
    save() {
      if ((!this.body.lat || !this.body.lng) && !this.body.cityId) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("Error"),
          detail: "You Should choose a location from map",
          life: 3000
        });

        return;
      }
      this.body.firstName = this.user.userName 
      this.body.email = this.user.email 
      this.body.phone = this.user.phone 

      if (
        this.body.cityId &&
        // this.body.regionId &&
        this.body.firstName &&
      
        this.body.address &&
        this.body.email &&
        this.body.phone
      ) {
        this.disabledAdd = true;

        this.$http.post("address", this.body).then(
          (res) => {
            delete sessionStorage.lat;
            delete sessionStorage.lng;

            this.disabledAdd = false;

            this.$router.push("/shippingAddress");
            this.$swal({
              title: this.$t("Successfully Done"),
              icon: "success",
              timer: 3000,
              showConfirmButton: false
            });
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: this.$t("Error"),
              detail: err.response.data.message,
              life: 3000
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: this.$t("Error"),
          detail: this.$t("Please add the required fields"),
          life: 3000
        });
      }
    },

    checkDescount() {
      this.$http
        .post("discounts/checkdiscountCode", {
          discountCode: this.discountCode
        })
        .then(
          (res) => {
            this.getCarts();
          },
          (err) => {
            this.$toast.add({
              severity: "error",
              summary: this.$t("Error"),
              detail: err.response.data.message,
              life: 3000
            });
          }
        );
    },
    deleteDiscount() {
      this.$http.post("cart/deleteDiscount", {}).then((res) => {
        this.getCarts();
      });
    },
    getCarts() {
      this.$http.get("cart").then(
        (res) => {
          if (res.data.returnStatus) {
            this.cart = res.data.data;
          } else {
            this.$router.push("/");
          }
        },
        (err) => {
          this.$router.push("/");
          this.$toast.add({
            severity: "error",
            summary: this.$t("Error"),
            detail: err.response.data.message,
            life: 3000
          });
        }
      );
    }
  },
  created() {
    this.getCarts();

    this.$http.post("users/getUserById", {}).then((res) => {
      this.user = res.data;
      
    });
    this.$http.get("country?limit=5000").then((res) => {
      this.countresList = res.data.docs;
    });

    if (sessionStorage.lat && sessionStorage.lng) {
      this.body.lat = sessionStorage.lat;
      this.body.lng = sessionStorage.lng;
    }
    
     this.$http.get("gov?limit=5000").then((res) => {
      this.govesList = res.data.docs;
    });
    // this.$http.get("city?limit=5000").then((res) => {
    //   this.citesList = res.data.docs;
    // });
  }
};
</script>

<style></style>
